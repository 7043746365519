/* ############################################# */
/* BASE */
/* ############################################# */

@import 'mixins';
@import 'settings';

// Lato font from Google Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');

// Roboto font from Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

// React input range base style
@import url('react-input-range/lib/css/index.css');

// General Colors
$purple: #6f4bf1;
$incavtiveUrl: #415766;
$avatarInitial: #21303a;
$paleOrange: #f19f73;

// Appointment Type Colors
$app-type-1: #81cc89; // ABA Therapy
$app-type-2: #81d6af; // Occupational Therapy
$app-type-3: #88ddea; // Medical
$app-type-4: #a0c5ff; // Speech Therapy
$app-type-5: #a0aeff; // MSE
$app-type-6: #c7acfd; // Music Therapy
$app-type-7: #e490f6; // Other
$app-type-8: #fdacd8; // Neuromodulation Therapists
$app-type-9: #fdb9a3; // Therapy
$app-type-10: #f38281; // Medical Providers
$app-type-11: #fdc784; // Physical Therapy
$app-type-12: #f9dc70; // Family Wellness Counseling
$app-type-13: #c0f88f; // CIM
$app-type-14: #cbcdc6; // Internal

// Appintment type Font-Color Classes
.at-1-color {
  color: $app-type-1;
}

.at-2-color {
  color: $app-type-2;
}

.at-3-color {
  color: $app-type-3;
}

.at-4-color {
  color: $app-type-4;
}

.at-5-color {
  color: $app-type-5;
}

.at-6-color {
  color: $app-type-6;
}

.at-7-color {
  color: $app-type-7;
}

.at-8-color {
  color: $app-type-8;
}

.at-9-color {
  color: $app-type-9;
}

.at-10-color {
  color: $app-type-10;
}

.at-11-color {
  color: $app-type-11;
}

.at-12-color {
  color: $app-type-12;
}

.at-13-color {
  color: $app-type-13;
}

.at-14-color {
  color: $app-type-14;
}

// Appintment type Background-Color Classes
.at-1-bg {
  background: $app-type-1;
}

.at-2-bg {
  background: $app-type-2;
}

.at-3-bg {
  background: $app-type-3;
}

.at-4-bg {
  background: $app-type-4;
}

.at-5-bg {
  background: $app-type-5;
}

.at-6-bg {
  background: $app-type-6;
}

.at-7-bg {
  background: $app-type-7;
}

.at-8-bg {
  background: $app-type-8;
}

.at-9-bg {
  background: $app-type-9;
}

.at-10-bg {
  background: $app-type-10;
}

.at-11-bg {
  background: $app-type-11;
}

.at-12-bg {
  background: $app-type-12;
}

.at-13-bg {
  background: $app-type-13;
}

.at-14-bg {
  background: $app-type-14;
}

body,
html {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  background: #ffffff;

  &.popup-open {
    overflow: hidden;
  }
}

.vh-centered {
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
}

.v-centered {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}

.h-centered {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
}

.eff01 {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.eff02 {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.eff03 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.clearfix {
  display: block;
  content: ' ';
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: inherit;
  color: inherit;
}

.inner-wrap {
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  height: 100%;
}

.input-wrap {
  input {
    width: 100%;
    background: #ffffff;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #21303a;
    height: 28px;
    border: 1px solid $purple;
    @include border-r(4px);
    padding: 0 8px;
    box-sizing: border-box;
    outline: 0;
  }

  .optional {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 10px;
    text-transform: uppercase;
    color: #21303a;
  }

  label {
    font-size: 14px;
    color: #252631;
    margin-bottom: 8px;
    display: block;
  }

  &.select {
    position: relative;

    svg {
      position: absolute;
      right: 12px;
      color: hsl(0, 0%, 60%);
      bottom: 4px;
      color: $purple;
      z-index: 1;
      pointer-events: none;
    }

    select {
      width: 100% !important;
      background: none !important;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #21303a;
      outline: 0 !important;
      padding: 0 !important;
      border: none !important;
      box-shadow: none !important;
      background-image: none !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
      cursor: pointer;
      position: relative;
      z-index: 1;

      &:focus {
        outline: none;
      }
    }
  }

  &.checkbox {
    margin: 0;
    position: relative;
    border: 0;
    box-sizing: border-box;

    label {
      display: block;
      position: relative;
      padding-left: 26px;
      margin-bottom: 0px;
      cursor: pointer;
      font-size: 15px;
      line-height: 18px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      text-align: left;
      top: 0px;
      left: 0px;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0 !important;
        width: 0 !important;

        &:checked ~ .checkmark {
          background-color: $purple;
        }

        &:checked ~ .checkmark:after {
          display: block;
        }
      }

      .checkmark {
        position: absolute;
        top: 1px;
        left: 0;
        height: 12px;
        width: 12px;
        @include border-r(2px);
        background-color: #ffffff;
        border: 2px solid $purple;

        &:after {
          content: '';
          position: absolute;
          display: none;
        }

        &:after {
          left: 3px;
          top: 0px;
          width: 4px;
          height: 8px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      &:hover input ~ .checkmark {
        background-color: $purple;
      }
    }
  }
}

.search-box {
  display: inline-block;
  margin-left: 24px;

  > div {
    position: relative;
    font-size: 12px;

    input {
      height: 32px;
      width: 140px;
      @include border-r(4px);
      background-color: #f4f4f4;
      border: 0;
      outline: 0;
      font-size: 14px;
      padding: 0 8px;
      box-sizing: border-box;
    }

    .icon {
      position: absolute;
      right: 0;
      top: 0;
      height: 32px;
      width: 32px;
      line-height: 32px;
      text-align: center;
      background: #c9cfd3;
      color: $incavtiveUrl;
      font-size: 14px;
      @include border-rtr(4px);
      @include border-rbr(4px);

      &:hover {
        background: $purple;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

/* ############################################# */
/* MAIN NAVIGATION */
/* ############################################# */

/* ############################################# */
/* USER NAVIGATION */
/* ############################################# */
#root {
  height: 100%;
}
.App {
  height: 100%;
}
.user-navigation-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #e8ecef;
  background: #ffffff;
  color: #778ca2;
  z-index: 100;

  .inner-wrap {
    height: 100%;
  }

  .nav-title {
    left: 26px;

    h1 {
      display: inline-block;
      margin-left: 24px;
      font-size: 20px;
      font-weight: 300;
      color: #252631;
    }

    i {
      font-size: 22px;
    }
  }

  .user-nav-left {
    left: 32px;
    display: block;
    padding: 0;
    margin: 0;

    li {
      display: block;
      margin: 0 8px;
      padding: 0;
      float: left;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      position: relative;

      &.logo-wrap {
        height: 42px;
        padding: 0;
        margin: 0;

        .logo {
          height: 100%;
        }
      }

      .icon {
        text-align: center !important;
        font-size: 20px;
        margin-bottom: 4px;
      }

      .underline {
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        height: 0px;
        width: 100%;
        background: $purple;
      }

      a {
        display: block;
        padding: 0 32px;
        color: $incavtiveUrl;

        &:after {
          content: attr(title);
          font-weight: bold;
          color: transparent;
          overflow: hidden;
          visibility: hidden;
          height: 0;
          display: block;
        }

        &.active {
          color: $purple;
          font-weight: bold;

          & + .underline {
            height: 4px;
          }
        }

        &:hover {
          color: $purple;
        }
      }
    }

    &:after {
      content: ' ';
      display: block;
      clear: both;
    }
  }

  .user-nav-right {
    right: 32px;
    display: block;
    padding: 0;
    margin: 0;

    > li {
      display: block;
      padding: 0;
      margin: 0 0 0 27px;
      float: left;
      cursor: pointer;

      &.avatar {
        width: 44px;

        > .wrap {
          width: 44px;
          height: 44px;
          @include border-r(50%);
          border: 1px solid #ededed;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          line-height: 44px;
          font-size: 20px;
          text-transform: uppercase;
          color: $avatarInitial;
          background-color: $paleOrange;
          text-align: center;
        }
      }

      i {
        font-size: 22px;
        vertical-align: sub;
      }

      .user-dropdown {
        position: absolute;
        right: 0;
        top: 18px;
        box-sizing: border-box;
        padding-top: 8px;
        display: none;

        &.active {
          display: block;
        }

        ul {
          display: block;
          margin: 0;
          padding: 0;
          width: 200px;
          border: 1px solid #ededed;
          @include border-r(4px);
          background: #ffffff;

          li {
            display: block;
            margin: 0;
            padding: 8px 16px;
            border-bottom: 1px solid #ededed;

            &:last-child {
              border-bottom: 0;
            }

            .email {
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 14px;
            }

            .hi {
              color: $paleOrange;
            }
          }
        }
      }
    }

    &:after {
      content: ' ';
      display: block;
      clear: both;
    }
  }
}

.col-20 {
  margin-right: 32px;
  float: left;
  position: relative;
  margin-bottom: 32px;
  @include calc-width('20%', '-', '26px');

  &:nth-child(5n + 5) {
    margin-right: 0px;
  }
}

.col-30 {
  margin-right: 56px;
  float: left;
  position: relative;
  margin-bottom: 32px;
  @include calc-width('33.333%', '-', '38px');

  &:nth-child(3n + 3) {
    margin-right: 0px;
  }
}

.col-50 {
  margin-right: 56px;
  float: left;
  position: relative;
  margin-bottom: 32px;
  @include calc-width('50%', '-', '38px');

  &:nth-child(2n + 2) {
    margin-right: 0px;
  }
}

/* ############################################# */
/* TOAST CONTAINER */
/* ############################################# */
.Toastify__toast-container {
  font-family: 'Lato';
  font-weight: bold;
  width: 418px !important;
}
.Toastify__toast {
  border-radius: 3px !important;
}
.Toastify__toast--success {
  background: #3d9912 !important;
  box-shadow: 0px 7px 0px rgb(168, 218, 168) !important;
  width: 414px;
}
.Toastify__toast--error {
  background: #e82424 !important;
  box-shadow: 0px 7px 0px rgb(224, 167, 167) !important;
  width: 414px;
}

/* ############################################# */
/* CONTENT */
/* ############################################# */

.signin-box {
  width: 320px;
  text-align: center;

  h2 {
    font-size: 24px;
    font-weight: 400;
    color: #21303a;
    margin-bottom: 8px;
  }

  p {
    box-sizing: border-box;
    padding: 0 16px;
    color: #919699;
    margin-bottom: 32px;
  }

  .cortica-logo {
    width: 120px;
    display: block;
    margin: auto;
    margin-bottom: 24px;
  }

  .microsoft-sign-in {
    padding: 14px 24px 14px 56px;
    box-sizing: border-box;
    position: relative;
    @include border-r(4px);
    background: #ededed;
    text-align: left;
    display: inline-block;
    color: #415766;
    margin: 3px;
    cursor: pointer;

    .logo {
      height: 16px;
      left: 24px;
    }

    &:hover {
      background: #1485c6;
      color: #ffffff;
    }
  }
}

.button-fill {
  display: inline-block;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  &.nif {
    background: #919699 !important;
    padding: 12px 24px !important;

    &:hover {
      background: #6f4bf1 !important;
    }
  }

  &.large {
    padding: 16px 40px;
    margin: 0;
    @include border-r(8px);
    background: #6f4bf1;
    color: #ffffff;
    box-sizing: border-box;
  }

  &.medium {
    padding: 8px 24px;
    margin: 0;
    @include border-r(8px);
    background: #6f4bf1;
    color: #ffffff;
    box-sizing: border-box;
  }

  &.small{
    padding: 9px 10px;
    margin: 0;
    @include border-r(4px);
    background: #6f4bf1;
    color: #ffffff;
    box-sizing: border-box;
  }
}

.edit-Avail{
  // padding: 10px 10px;
  height: 38px;
  padding: 0px 18px;
  border-radius: 4px;
  border: 1px solid #6f4bf1 ;
  color: #6f4bf1;
  &:hover{
    color: white;
    background-color: #6f4bf1;
  }
}

.button-outline {
  display: inline-block;
  cursor: pointer;

  &.medium {
    height: 39px;
    padding: 8px 24px;
    margin: 0;
    @include border-r(8px);
    border: 1px solid $purple;
    color: $purple;
    box-sizing: border-box;

    &:hover {
      background: $purple !important;
      color: #ffffff !important;
    }
  }
}

.button {
  display: inline-block;
  cursor: pointer;

  &.medium {
    padding: 8px 24px;
    margin: 0;
    @include border-r(8px);
    color: $incavtiveUrl;
    box-sizing: border-box;

    &:hover {
      color: $purple !important;
    }
  }
}

.popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #f6f6f6;
  height: 100%;
  width: 100%;
  overflow: auto;

  .nav {
    background-color: #ffffff;
    position: relative;
    border-bottom: 1px solid #ededed;

    .inner-wrap {
      padding: 24px 16px !important;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 26px;
        font-weight: 600;
        display: inline-block;
      }

      .button-fill {
        background: #e8ecef;
        color: #919699;

        &:hover {
          color: #ffffff;
          background: $purple;
        }
      }

      .right {
        float: right;
        margin-left: 16px;
      }
    }
  }

  .form-wrap {
    .select {
      select {
        background: #ffffff !important;
        padding: 0 8px !important;
        height: 30px;
        border: 1px solid $purple !important;
        @include border-r(4px);
        width: 100% !important;
        font-size: 14px !important;
      }

      svg {
        bottom: 9px;
      }
    }
  }
}

.content {
  width: 100%;
  box-sizing: border-box;
  padding-top: 64px;
  float: right;
  height: 100%;

  .bread-crumbs {
    background-color: #ffffff;
    position: relative;

    .angle {
      display: inline-block;
      margin: 0 10px 0;
      font-size: 14px;
      color: #000000;
    }

    a {
      font-size: 14px;
      font-weight: bold;
      color: #000000;

      &:hover {
        color: $purple;
      }
    }
  }

  .client-tabs {
    display: block;
    margin: 0;
    padding: 0;

    li {
      display: block;
      margin: 0;
      width: 33.3333% !important;
      padding: 18px 0;
      box-sizing: border-box;
      text-align: center;
      color: $incavtiveUrl;
      text-transform: uppercase;
      font-size: 14px;
      cursor: pointer;

      span {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: $purple;
      }

      &.active {
        background-color: #ffffff !important;

        span {
          display: block;
          background: $purple !important;
        }
      }

      &:hover {
        background-color: #ededed;

        span {
          display: block;
          background: #cccccc;
        }
      }
    }
  }

  .staff-tabs {
    display: block;
    margin: 0;
    padding: 0;

    li {
      display: block;
      margin: 0;
      width: 50% !important;
      padding: 18px 0;
      box-sizing: border-box;
      text-align: center;
      color: $incavtiveUrl;
      text-transform: uppercase;
      font-size: 14px;
      cursor: pointer;

      span {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: $purple;
      }

      &.active {
        background-color: #ffffff !important;

        span {
          display: block;
          background: $purple !important;
        }
      }

      &:hover {
        background-color: #ededed;

        span {
          display: block;
          background: #cccccc;
        }
      }
    }
  }

  .profile-info-wrap {
    background-color: #ffffff;
    border-bottom: 1px solid #e8ecef;
    position: relative;
    padding-bottom: 32px;
    .inner-wrapper {
      width: 1022px;
      margin-left: 25%;
      .table-title {
        padding-top: 27px;
        padding-left: 0;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.15px;
        color: #000000;
      }
      .ant-table-content {
        &:after {
          content: none;
        }
      }
      .ant-table-wrapper {
        margin-top: 16px;
        table {
          border-collapse: collapse;
          border-top: none;
          border-left: none;
          .ant-table-column-title {
            font-family: Lato;
            font-size: 18px;
            font-weight: bold;
            color: #21303a;
          }
          th {
            padding-left: 9px;
            padding-right: 0;
          }
          .ant-table-expand-icon-th {
            position: absolute;
            border: none;
            background: inherit;
          }
          .ant-table-row-level-0 {
            background: #ffffff;
            position: relative;
            .ant-table-row-expand-icon-cell {
              position: absolute;
              z-index: 5;
              border: none;
              background: none;
              padding-left: 4px;
              text-align: left;
              font-size: 20px;
              color: $purple;
            }
            &:nth-of-type(odd) {
              background: #ececec;
            }
            td {
              .ant-btn {
                background: transparent;
                &:hover {
                  background: $purple;
                }
              }
            }
          }
          .ant-table-expanded-row-level-1 {
            border: solid 2px #c4c6c8;
            background: #ececec;
            td {
              padding-left: 16px !important;
              &:first-child {
                border: none;
                background: inherit;
              }
              .description-wrapper {
                display: flex;
              }
              p {
                font-family: Lato;
                font-size: 12px;
                letter-spacing: 0.13px;
                color: $avatarInitial;
                padding-right: 24px;
                span {
                  font-weight: bold;
                }
              }
            }
          }
          td {
            height: 51px;
            padding: 9px 0 8px 9px;
            box-sizing: border-box;
            border-top: none;
            border-bottom: none !important;
            &:nth-child(2) {
              padding-left: 28px;
            }
          }
        }
      }
    }

    .large {
      width: 215px;
      height: 53px;
      font-weight: 400;
      span {
        margin-left: 0;
      }
    }

    .edit-button {
      position: absolute;
      top: 64px;
      right: 40px;
      height: 39px;
    }

    .edit-button-2 {
      position: absolute;
      top: 120px;
      right: 40px;
    }
    .edit-info-btn{
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      color: #6F42F5;
      text-decoration: underline !important;
      border: none;
      box-shadow: none;
      position: relative;
      bottom: 6px;
    }
    .edit-info-inner{
      text-decoration: underline;
    }
    .overall-btn-cont{
      display: flex;
      flex-direction: column;
      /* justify-content: end; */
      /* align-items: end; */
      justify-content: space-between;
      height: 130px;
      align-items: center;
    }

    .actions {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
      display: block;

      li {
        &.info {
          color: #919699;
          padding-top: 8px;

          span {
            color: #252631;
          }
        }

        padding: 0;
        margin: 0;
        float: left;
        margin-left: 16px;
        display: block;

        .select {
          width: 140px;
          margin-top: 16px;
        }
      }
    }

    .avatar {
      width: 128px;
      height: 128px;
      @include border-r(50%);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 1px solid #ededed;
      float: left;
      position: relative;

      .badge {
        padding: 4px 16px;
        @include border-r(12px);
        background-color: #02bf00;
        color: #ffffff;
        position: absolute;
        bottom: -32px;
        width: auto;
        white-space: nowrap;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #21303a;
      }
    }

    .profile-info {
      display: block;
      float: left;
      margin: 0 0 0 24px;
      padding: 0;

      a {
        color: $purple;
        text-decoration: underline;
        cursor: pointer;
      }

      > li {
        display: block;
        margin: 0;
        padding: 0;

        &.name {
          font-size: 24px;
          color: #21303a;
          margin-bottom: 8px;
        }

        &.url {
          color: #0d77b5;
          margin-bottom: 8px;
          font-size: 14px;
          text-decoration: underline;
        }

        &.status {
          font-size: 14px;
          color: #000000;
          margin-bottom: 6px;
          font-weight: 600;

          span {
            display: inline-block;
            border: 1px solid #02bf00;
            @include border-r(24px);
            padding: 2px 8px;
            margin-left: 8px;
            text-transform: uppercase;
            font-size: 11px !important;
            font-weight: 600;
          }

          &.staff {
            span {
              display: inline-block;
              @include border-r(24px);
              border: none !important;
              padding: 4px 8px;
              margin-left: 8px;
              text-transform: uppercase;
              font-size: 11px !important;
              font-weight: 600;
              color: #252631;
            }
          }
        }

        &.date {
          margin-bottom: 6px;

          span {
            font-size: 16px;
            display: inline-block;
            margin-right: 16px;
          }
        }

        &.clinic {
          color: #000000;
          margin-bottom: 6px;
          font-weight: 600;

          span {
            font-size: 16px;
            font-weight: 400;
            display: inline-block;
            margin-left: 8px;
          }
        }
        &.client-type {
          color: #000000;
          margin-bottom: 6px;
          font-weight: 600;

          span {
            font-size: 16px;
            font-weight: 400;
            display: inline-block;
            margin-left: 8px;
          }
        }
        &.client-email {
          color: #000000;
          margin-bottom: 6px;
          font-weight: 500;
          font-size: 14px;
          span {
            //font-size: 16px;
            font-weight: 400;
            display: inline-block;
            margin-left: 8px;
          }
        }
        ul {
          display: block;
          margin: 0;
          padding: 0;

          li {
            display: inline-block;
            margin-right: 40px;
            font-size: 14px;
            color: #919699;

            span {
              color: #21303a;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .profile-pic-container {
      display: none !important;
    }
  }

  .title-wrap {
    background-color: #ffffff;
    border-bottom: 1px solid #e8ecef;
    height: 96px;
    position: relative;

    .actions {
      right: 18px;
      padding: 0;
      margin: 0;

      li {
        display: block;
        padding: 0;
        margin: 0 0 0 24px;
        float: left;

        .button-fill {
          display: inline;
          vertical-align: sub;
        }

        &.search {
          > div {
            position: relative;
            font-size: 12px;

            input {
              height: 32px;
              width: 300px;
              @include border-r(4px);
              background-color: #f4f4f4;
              border: 0;
              outline: 0;
              font-size: 14px;
              padding: 0 12px;
              box-sizing: border-box;
            }

            .icon {
              position: absolute;
              right: 0;
              top: 0;
              height: 32px;
              width: 32px;
              line-height: 32px;
              text-align: center;
              background: #c9cfd3;
              color: $incavtiveUrl;
              font-size: 14px;
              @include border-rtr(4px);
              @include border-rbr(4px);

              &:hover {
                background: $purple;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
      }

      &:after {
        content: ' ';
        display: block;
        clear: both;
      }
    }

    h1 {
      left: 18px;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0.38px;
    }
  }

  .clearfix {
    &:after {
      content: ' ';
      display: block;
      clear: both;
    }
  }

  .inner-wrap {
    box-sizing: border-box;
    padding: 16px 16px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;

    &:after {
      content: ' ';
      display: block;
      clear: both;
    }

    &.list {
      padding-top: 0px;
    }
  }

  .list-wrap {
    &.clients {
      .list-titles {
        display: grid;
        grid-template-columns: 15% 10% 10% 12% 15% 20% 25%;
        background: #ffffff;
        margin: 0;
        padding: 0;
        border: 1px solid #e8ecef;
        border-top: 0;
        @include border-rbl(4px);
        @include border-rbr(4px);

        li {
          display: block;
          margin: 0;
          padding: 0;
          padding: 16px 24px;
          color: #919699;

          .sort {
            vertical-align: baseline;
            margin-left: 4px;
          }

          .filter {
            font-size: 11px;
            margin-left: 4px;
          }
        }
      }

      .list {
        .list-item {
          display: grid;
          grid-template-columns: 15% 10% 10% 12% 15% 20% 25%;
          background: #ffffff;
          margin: 0;
          padding: 0;
          border: 1px solid #e8ecef;
          @include border-r(4px);
          margin-top: 8px;

          li {
            display: block;
            margin: 0;
            padding: 24px 24px;
            color: $incavtiveUrl;

            .sort {
              vertical-align: baseline;
              margin-left: 4px;
              color: $purple;
            }
          }
        }
      }
    }

    &.staff {
      .list-titles {
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        background: #ffffff;
        margin: 0;
        padding: 0;
        border: 1px solid #e8ecef;
        border-top: 0;
        @include border-rbl(4px);
        @include border-rbr(4px);

        li {
          display: block;
          margin: 0;
          padding: 0;
          padding: 16px 24px;
          color: #919699;

          .sort {
            vertical-align: baseline;
            margin-left: 4px;
          }

          .filter {
            font-size: 11px;
            margin-left: 4px;
          }
        }
      }

      .list {
        .list-item {
          display: grid;
          grid-template-columns: 20% 20% 20% 20% 20%;
          background: #ffffff;
          margin: 0;
          padding: 0;
          border: 1px solid #e8ecef;
          @include border-r(4px);
          margin-top: 8px;

          li {
            display: block;
            margin: 0;
            padding: 24px 24px;
            color: $incavtiveUrl;

            .sort {
              vertical-align: baseline;
              margin-left: 4px;
              color: $purple;
            }
          }
        }
      }
    }

    &.auth {
      .list-titles {
        display: grid;
        grid-template-columns: 16% 16% 18% 14% 12% 14% 10%;
        background: #ffffff;
        margin: 0;
        padding: 0;
        border: 1px solid #e8ecef;
        border-top: 0;
        @include border-rbl(4px);
        @include border-rbr(4px);

        li {
          display: block;
          margin: 0;
          padding: 0;
          padding: 16px 24px;
          color: #919699;

          .sort {
            vertical-align: baseline;
            margin-left: 4px;
          }

          .filter {
            font-size: 11px;
            margin-left: 4px;
          }
        }
      }

      .list {
        .list-item {
          display: grid;
          grid-template-columns: 16% 16% 18% 14% 12% 14% 10%;
          background: #ffffff;
          margin: 0;
          padding: 0;
          border: 1px solid #e8ecef;
          @include border-r(4px);
          margin-top: 8px;

          li {
            display: block;
            margin: 0;
            padding: 24px 24px;
            color: $incavtiveUrl;

            .view {
              color: #919699;
              margin-right: 16px;
            }

            .sort {
              color: $purple;
              vertical-align: baseline;

              &.up {
                vertical-align: -webkit-baseline-middle;
                display: none;
              }
            }

            &:last-child {
              text-align: right;
            }
          }
        }

        .auth-dropdown {
          background: #ffffff;
          padding: 16px 0;
          box-sizing: border-box;
          display: none;

          ul {
            display: grid;
            grid-template-columns: 8% 16% 10% 12% 14% 14% 18%;
            background: #ffffff;
            margin: 0;
            padding: 0;
            border-top: 0;
            @include border-rbl(4px);
            @include border-rbr(4px);

            li {
              display: block;
              margin: 0;
              padding: 0;
              padding: 4px 16px;
              color: #919699;
              font-size: 14px;

              .sort {
                vertical-align: baseline;
                margin-left: 4px;
              }

              .filter {
                font-size: 11px;
                margin-left: 4px;
              }
            }
          }
        }

        .active {
          .auth-dropdown {
            display: block !important;
          }

          .sort {
            display: none;

            &.up {
              display: inline-block !important;
            }
          }
        }
      }

      &.client {
        .list-titles {
          display: grid;
          grid-template-columns: 8% 14% 6% 12% 10% 10% 18% 10% 12%;
          background: #ffffff;
          margin: 0;
          padding: 0;
          border: 1px solid #e8ecef;
          border-top: 0;
          @include border-rbl(4px);
          @include border-rbr(4px);

          li {
            display: block;
            margin: 0;
            padding: 0;
            padding: 16px;
            color: #919699;

            .sort {
              vertical-align: baseline;
              margin-left: 4px;
            }

            .filter {
              font-size: 11px;
              margin-left: 4px;
            }
          }

          .button-outline {
            padding: 4px 12px;
            margin: -2px 0 0 0;
            display: inline-block;
            font-size: 12px;
            font-weight: 600;
          }
        }

        .list {
          .list-item {
            display: grid;
            grid-template-columns: 60% 18% 22%;
            background: #ffffff;
            margin: 0;
            padding: 0;
            border: 1px solid #e8ecef;
            @include border-r(4px);
            margin-top: 8px;

            li {
              display: block;
              margin: 0;
              padding: 16px;
              color: $incavtiveUrl;

              .view {
                color: #919699;
                margin-right: 16px;
              }

              .sort {
                color: $purple;
                vertical-align: baseline;
                margin-left: 8px;

                &.up {
                  vertical-align: -webkit-baseline-middle;
                  display: none;
                }
              }

              &:last-child {
                text-align: left;

                .view {
                  margin-left: 16px;
                }
              }
            }
          }

          .client-auth-dropdown {
            background: #ffffff;
            padding: 16px 0;
            box-sizing: border-box;
            display: none;

            ul {
              display: grid;
              grid-template-columns: 8% 14% 6% 12% 10% 10% 18% 10% 12%;
              background: #ffffff;
              margin: 0;
              padding: 0;
              border-top: 0;
              @include border-rbl(4px);
              @include border-rbr(4px);

              li {
                display: block;
                margin: 0;
                padding: 0;
                padding: 4px 16px;
                color: #919699;
                font-size: 14px;

                .sort {
                  vertical-align: baseline;
                  margin-left: 4px;
                }

                .filter {
                  font-size: 11px;
                  margin-left: 4px;
                }
              }
            }
          }

          .active {
            .client-auth-dropdown {
              display: block !important;
            }

            .sort {
              display: none;

              &.up {
                display: inline-block !important;
              }
            }
          }
        }
      }
    }

    &.clinics {
      .list {
        display: block;
        padding: 0;
        margin: 0;

        > li {
          background: #ffffff;
          margin: 0;
          padding: 16px 24px;
          box-sizing: border-box;
          border: 1px solid #e8ecef;
          float: left;
          display: block;
          width: 25%;
          @include calc-width('25%', '-', '12px');
          @include border-r(4px);
          margin: 8px 16px 0 0;

          &:nth-child(4n + 4) {
            margin-right: 0;
          }

          h2 {
            font-size: 18px;
            color: #21303a;
            margin-bottom: 6px;

            a {
              float: right;
              color: #919699;
              margin-right: -4px;
              cursor: pointer;

              &:hover {
                color: $purple;
              }
            }
          }

          h3 {
            font-size: 16px;
            color: $incavtiveUrl;
            color: #415766;
            margin-bottom: 6px;
          }

          p {
            font-size: 16px;
            color: #21303a;
            font-weight: bold;
            margin-bottom: 16px;
          }

          .hours {
            display: block;
            padding: 0;
            margin: 0;

            li {
              display: block;
              margin: 0 0 8px 0;
              padding: 0;
              color: $incavtiveUrl;
              width: 60%;

              span {
                float: right;
              }
            }
          }

          .stats {
            display: block;
            padding: 0;
            margin: 24px 0 0 0;

            li {
              display: block;
              margin: 0 0 0 0;
              padding: 0;
              color: $incavtiveUrl;
              width: 33.333%;
              float: left;
              text-align: center;
              font-size: 24px;
              color: $purple;

              span {
                display: block;
                font-size: 15px;
                color: #21303a;
              }
            }
          }
        }
      }
    }

    .list-item {
      cursor: pointer;

      &:hover {
        background: #efefef !important;
      }
    }
  }

  .bc-wrap {
    display: block;
    padding: 0;
    margin: 0 0 32px 0;

    li {
      display: block;
      padding: 0;
      margin: 0;
      float: left;
      font-size: 22px;
      font-weight: normal;
      color: #778ca2;

      i {
        margin-right: 12px;
      }
    }

    &:after {
      content: ' ';
      display: block;
      clear: both;
    }
  }

  .calendar-navigation {
    position: relative;

    .date {
      width: 200px;
      text-align: center;
      color: #252631;
      font-size: 14px;
      line-height: 19px;
    }

    .buttons {
      padding: 0;
      border: 1px solid #e8ecef;
      display: inline-block;
      margin: 0;
      @include border-r(6px);
      overflow: hidden;

      li {
        display: block;
        padding: 15px 15px 14px 15px;
        margin: 0;
        font-size: 14px;
        float: left;
        background: #ffffff;
        box-sizing: border-box;
        color: #778ca2;
        border-right: 1px solid #e8ecef;
        cursor: pointer;

        &.icon {
          padding: 14px 15px 11px 15px;

          i {
            font-size: 18px;
          }
        }

        &:last-child {
          border: 0 !important;
        }

        &:hover {
          background: #f2f4f6;
        }

        &.active {
          background: #f2f4f6;
        }
      }

      &.left {
        float: left;
      }

      &.right {
        float: right;
      }
    }

    &:after {
      content: ' ';
      display: block;
      clear: both;
    }
  }
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: transparent;
}

.nif-button {
  display: flex;
  align-items: center;

  button {
    margin-right: 30px;
  }

  > span {
    font-size: 15px;
    color: #919699;
  }
}

.ant-popover:not(.viewSurrounding) {
  .ant-popover-inner-content {
    min-width: 678px;
    padding: 12px 170px;
  }
}

.ant-popover {
  &.viewCompleted {
    width: 366px;
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner {
        height: 100%;
        .ant-popover-inner-content {
          min-width: auto;
          padding: 0;
          .contentWrapper {
            table {
              background: $avatarInitial;
              padding: 12px 15px 10px 15px;
              .ant-table-thead {
                tr {
                  margin-left: -3px;
                  th {
                    background: $avatarInitial;
                    border-bottom: none;
                    padding: 0;
                    position: relative;
                    left: -3px;
                    span {
                      font-family: Lato;
                      font-size: 15px;
                      font-weight: bold;
                      color: #ffffff;
                    }
                  }
                }
              }
              .ant-table-tbody {
                .ant-table-row-level-0 {
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                  &:hover {
                    td {
                      background: inherit;
                    }
                  }
                  td {
                    padding: 0;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    color: #ffffff;
                    span {
                      position: relative;
                      left: -3px;
                      font-family: Lato;
                      font-size: 15px;
                      font-weight: normal;
                      color: #ffffff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.popoverInfo {
    width: 500px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #c9cfd3;
    background-color: #ffffff;
    padding: 0;
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner-content {
        padding: 0;
        min-width: 100%;
        .headerPopover {
          display: flex;
          align-items: center;
          padding: 15px 0 14px 24px;
          height: 57px;
          .patientPhoto {
            width: 28px;
            height: 28px;
            margin-right: 16px;
            img {
              width: 100%;
              border-radius: 50%;
            }
          }
          .patientName {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            color: #000000;
          }
        }
        .ant-table {
          border-radius: none;
          .ant-table-body {
            table {
              border-radius: 0;
              .ant-table-thead {
                tr {
                  .ant-table-row-cell-break-word {
                    background: #ffffff;
                    padding: 10px 0 14px 9px;
                    .ant-table-header-column {
                      .ant-table-column-title {
                        width: 45.8px;
                        height: 22px;
                        opacity: 0.8;
                        font-family: Lato;
                        font-size: 18px;
                        font-weight: bold;
                        color: $avatarInitial;
                        border-color: #d8d8d8;
                      }
                    }
                  }
                }
              }
              .ant-table-tbody {
                .ant-table-row-level-0 {
                  .ant-table-row-cell-break-word {
                    background: #ececec;
                    padding: 16px 13px 16px 8px;
                    font-family: Lato;
                    font-size: 14px;
                    letter-spacing: 0.15px;
                    color: $avatarInitial;
                    vertical-align: baseline;
                    border-color: #d8d8d8;
                    line-height: 17px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.tooltipStyle {
  left: 28px !important;
  padding: 0;
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    background: $avatarInitial;
    text-align: center;
    // width: 155px;
    height: 39px;
    line-height: 26px;
  }
}

.ant-popover-buttons {
  padding-top: 30px;

  button:first-child {
    border: none;
    color: #919699;
    font-size: 15px;
    outline: none;
  }

  button:last-child {
    background: transparent;
    border: none;
    box-shadow: none;
    color: #0d77b5;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    outline: none;

    span {
      text-decoration: underline;
    }
  }
}

.ant-select {
  .ant-select-selection {
    box-shadow: none !important;
  }
}
.custom-dropdown{
  .ant-select-item-option-active:not(.ant-select-item-option-disabled){
    background-color: #6f4bf1;
    color:white
  }
  }

.ant-select-dropdown {
  border: 1px solid #6f4bf1;
  box-shadow: none;
  .ant-select-dropdown-menu {
    min-height: 45px;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
        inset 1px 1px 0px rgba(0, 0, 0, 0);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .ant-select-dropdown-menu-item {
      min-height: 25px;
    }
  }
}
.custom-dropdown-select{
  width: fit-content !important;
  border-radius: 8px;
}

// SEMANTIC-UI MODAL Dimmer BG
.modals.dimmer {
  background-color: rgba(200, 201, 204, 0.5) !important;
}

// Global STYLING ant-radio-group
.ant-radio-group {
  width: 100%;
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    .ant-radio {
      box-shadow: none;
      .ant-radio-inner {
        background-color: #fff;
        border: 1px solid #c9cfd3;
      }
      &.ant-radio-checked {
        .ant-radio-inner {
          border: 1px solid #6f4bf1;
          &:after {
            height: 7px;
            width: 7px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: none;
          }
        }
      }
    }
  }
}

.ui.popup {
  max-width: unset !important;
}
