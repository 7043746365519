.main_btn_wrapper {
  .ui.inverted.purple.button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 46px;
    width: 140px;
    font-size: 15px;
    box-shadow: 0 0 0 2px#6f4bf1 inset !important;
    background-color: #6f4bf1;
    color: #fff;
    border-radius: 7px;
    &:hover {
      background-color: #fff !important;
      color: #6f4bf1;
      border: 1px solid #6f4bf1;
    }
    &:disabled {
      box-shadow: 0 0 0 2px #e7eaed inset !important;
    }
    transition: all .1s ease-in-out;
  }
  .ui.button:disabled {
    color: #919699 !important;
    background-color: #e7eaed !important;
    border: none;
    box-shadow: 0 0 0 2px #e7eaed inset !important;
    border-color: #e7eaed !important;
  }
  &.availabilty-template-btn {
    .ui.inverted.purple.button {
      background-color: #fff !important;
      color: #6f4bf1;
      border: 1px solid #6f4bf1;
      &:hover {
        box-shadow: 0 0 0 2px#6f4bf1 inset !important;
        background-color: #6f4bf1 !important;
        color: #fff;
      }
      &:disabled {
        box-shadow: 0 0 0 2px #e7eaed inset !important;
      }
    }
  }
}
