.wl-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transition: width 1s;
  background-color: hsl(0, 0%, 100%);
  display: none;
  z-index: 1000;
  /* animation: smooth-fade-in 1s; */
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}

.wl-sidebar.open {
  width: 450px;
  display: flex;
  flex-direction: column;
}

@keyframes smooth-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.sidebar-header {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  padding: 0px 10px;
  /* padding-right: 1px; */
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll !important;
  scrollbar-width: none; /* Firefox */
}
.sidebar-body .scroll-y {
  overflow-y: auto;
}

.sidebar-body-header {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-color: #636b744d;
  gap: 10px;
}

.sidebar-body-content {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  flex-grow: 1;
  text-align: left;
}

.sidebar-notes-patient-details {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.sidebar-notes-patient-name {
  color: #21303a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-top: 10px;
}

.sidebar-cancel {
  margin-top: 10px;
  margin-bottom: 10px;
}
.sidebar-header-list {
  width: 100%;
  border-bottom: 1px solid #636b74;
}
.sidebar-notes-patient-dob {
  color: #415766;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
}

.search-container {
  position: relative;
  width: 200px;
  margin-top: 20px;
}

.search-container input {
  width: 100%;
  padding: 10px;
  color: #415766;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142%;
  border: 1px solid #e8e8e8;
  background: #fff;
  border-radius: 6px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.notes-options {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  height: 25px;
}
.notes-options-span {
  color: #415766;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142%;
  /* 19.88px */
}
.notes-saved-div {
  display: flex;
  margin-top: 10px;
  padding: 5px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #d8f4e2;
  border-radius: 6px;
}
.note-saved-message {
  color: var(--success-soft-Color, #0a470a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.createnote-button-div {
  min-height: 40px;
  margin-top: 10px;
}
.sidebar-note-details {
  height: 30%;
}
.sidebar-notes {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* max-height: 62vh;
  /* Adjust the height as needed */
}

.sidebar-notes ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* Enable vertical scrolling */
  /* max-height: 300px; Set a maximum height for the container */
}

.sidebar-notes li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid grey;
}
.create-notes-form .quill {
  width: 100%;
}
.sidebar-notes .notes-item ul li,
.sidebar-notes .notes-item ol li {
  margin-bottom: 0px;
  padding: 0px;
  background-color: #ffffff;
  border-radius: 0px;
  border: none;
}
.sidebar-notes .notes-item ul {
  list-style-type: disc !important;
  padding-inline-start: 40px;
}
.sidebar-notes .notes-item p,
.sidebar-notes .notes-item h1,
.sidebar-notes .notes-item h2 {
  padding: 0px;
  margin: 0px;
}
.sidebar-notes .notes-item p .ql-size-huge {
  font-size: 2.5em;
  font-weight: bold;
}
.sidebar-notes .notes-item p .ql-size-large {
  font-size: 1.5em;
  font-weight: 600;
}
.sidebar-notes .notes-item p .ql-size-small {
  font-size: 0.75em;
}
.sidebar-notes li.notes-error {
  border-color: #c41c1c;
  margin-bottom: 30px;
}
.sidebar-notes li.notes-error span.notes-item-content {
  color: #c41c1c;
}
.sidebar-notes li.notes-error .error-msg {
  color: #c41c1c;
  position: relative;
  bottom: -35px;
}
.edit-tab-content {
  flex: 1; /* Ensure both sections take up equal space */
  padding: 20px;
  overflow-y: auto;
}

.sidebar-notes li:hover {
  background-color: white;
}

.sidebar-notes li a {
  text-decoration: none;
  color: #333;
}

.sidebar-notes li a:hover {
  text-decoration: underline;
}

.notes-item {
  display: flex;
  flex-direction: column;
}

.notes-item-header {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  color: #8f8f8f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
}

.notes-item-content {
  color: #415766;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142%;
  word-wrap: break-word;
}

.sidebar-body-content-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.sidebar-body-content-item h3 {
  font-size: 18px;
  margin-bottom: 8px;
}

.sidebar-body-content-item p {
  font-size: 14px;
  color: #666;
}

.sidebar-footer {
  display: flex;
  width: 462px;
  height: auto;
  padding: 24px 0px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  position: fixed;
  bottom: 0;
}
.sidebar-footer-bordertop {
  border-top: 1px solid grey;
}

.create-notes-form {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 15px;
}
.sidebar-header-selected {
  margin-left: 10px;
  cursor: pointer;
}
.sidebar-header-list .first {
  margin-left: 0px;
}
.sidebar-header-list .active {
  color: #6f42f5;
  border-bottom: 1px solid #6f42f5;
}

.create-note-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}
.save-note-button {
  width: 136px;
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  background: #6f42f5;
  color: white;
  border: none;
  cursor: pointer;
}
.save-note-button-disabled {
  opacity: 0.6 !important;
  background: gray;
}

.cancel-note-button {
  width: 136px;
  height: 40px;
  padding: 8px;
  background: white;
  border-radius: 4px;
  border: 1px solid #6f42f5;
  cursor: pointer;
}

.create-note-input {
  width: 410px;
  height: 150px;
  background: white;
  border: 1px solid #6f42f5;
  border-radius: 3px;
  resize: none;
  padding: 10px;
  margin-left: -10px;
}
.create-note-input:focus-visible {
  outline: 1px solid #6f42f5;
}

.create-note-input::placeholder {
  color: #415766;
  /* body-sm */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142%;
}

.createnote-button-div button {
  /* Add space between the buttons */
  cursor: pointer;
  float: right;
}

.sidebar-footer-upwardarrow {
  margin-left: auto;
  /* Move the "^" button to the right */
  min-width: 40px;
  min-height: 40px;
  border: 1px solid #6f42f5;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
}

.createnote-button {
  width: 136px;
  height: 40px;
  background-color: #6f42f5;
  color: white;
  border: none;
  border-radius: 6px;
}

.sidebar-careplan {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700; /* Set font weight to 500 for "Careplan:" */
  line-height: 142%; /* 19.88px */
  color: var(--Header-Text, #21303a); /* Default color for care plan status */

  /* Set display to flex and align items to center for proper layout */
  display: flex;
  align-items: center;
}
.sidebar-careplan-requested {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700; /* Set font weight to 500 for "Careplan:" */
  line-height: 142%; /* 19.88px */
  color: var(--Header-Text, #21303a); /* Default color for care plan status */

  /* Set display to flex and align items to center for proper layout */
  display: flex;
  align-items: center;
}

.sidebar-careplan-status {
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700; /* Set font weight to 500 for "Careplan:" */
  line-height: 142%; /* 19.88px */
  color: var(--Header-Text, #21303a); /* Default color for care plan status */

  /* Set display to flex and align items to center for proper layout */
  display: flex;
  align-items: center;
}
.sidebar-careplan,
.sidebar-careplan-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-careplan-name {
  margin-left: 12px;
  color: var(--Primary-Text, #415766); /* Default color for care plan name */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142%; /* 19.88px */
}

.sidebar-dropdown {
  width: '200px';
  height: '40px';
  display: 'inline-flex';
  flex-direction: 'column';
  align-items: 'flex-start';
  border-radius: '8px';
  background: 'var(--background-body, #FFF)';
}
.ant-select-arrow {
  color: grey !important;
}
.ant-select-selector {
  border-radius: 8px !important;
}
.sidebar-careplan-recommended {
  margin-top: -5px;
  color: var(--Primary-Text, #415766);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 142%; /* 19.88px */
}
.error-msg {
  color: #c41c1c;
  position: relative;
  bottom: 0px;
}
.error-msg-edit {
  color: #c41c1c;
  position: relative;
  bottom: 0px;
}
.error-msg-edit span {
  padding-top: 10px;
}
.select-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 55px; 
  min-height: 50px;
  overflow: hidden; */
}
.ant-select-arrow {
  top: 42%;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 200px;
}
