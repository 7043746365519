.ant-popover:not(.viewSurrounding) {
  .ant-popover-inner-content {
    min-width: 383px;
    max-width: 383px;
    padding: 21px 13px 19px 13px;
    font-family: Lato;
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #3e5766;
    label {
      font-weight: bold;
    }
  } 
}

.clientPopOver .ant-popover-inner .ant-popover-inner-content {
  max-width: fit-content;
  min-width: 200px;
}


.ant-popover-title {
  background: #6f42f5;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.2px;
}
.ui.left.center.popup {
  background: #f7f7f7 !important;
  border-radius: 5px;
  padding: 0;
  margin: 0;
}
.ui.right.center.popup {
  padding: 0;
}
.ui.vertical.menu,
.ui.vertical.menu .item {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.01em;
  color: #3e5766;
  border: none;
  max-width: 166px;
  margin: 0;
  background: #f7f7f7 !important;
}
.ui.vertical.menu .item {
  &:hover {
    background-color: #6f42f5 !important;
    color: #ffffff !important;
  }
}
.ui.vertical.menu .item:before {
  display: none;
}
.ui.vertical.menu > .item:last-child {
  margin-top: 4px;
}
